<template>
  <a-modal
    wrap-class-name="payee-bank-choose"
    :visible="visible"
    :width="920"
    :title="$t('pages_pay_242')"
    :mask-closable="false"
    @cancel="$emit('update:visible', false)"
    @ok="handleSubmit"
  >
    <a-form layout="vertical" class="search-form">
      <a-form-item :label="$t('pages_pay_243')">
        <a-select
          v-model:value="searchQuery.provinceCode"
          allow-clear
          show-search
          option-filter-prop="label"
          style="width: 200px"
          @change="provinceChange"
        >
          <a-select-option
            v-for="item in provinces"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('pages_pay_244')" style="margin-left: 24px">
        <a-select
          v-model:value="searchQuery.cityCode"
          allow-clear
          show-search
          option-filter-prop="label"
          style="width: 200px"
          @change="loadBankList(1)"
        >
          <a-select-option
            v-for="item in cities"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('pages_pay_245')" style="margin-left: 24px">
        <a-select
          v-model:value="searchQuery.parentBankCode"
          allow-clear
          show-search
          option-filter-prop="label"
          style="width: 200px"
          @change="loadBankList(1)"
        >
          <a-select-option
            v-for="item in generalBanks"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('pages_pay_246')" style="margin-left: 24px">
        <a-input
          v-model:value="searchQuery.bankName"
          allow-clear
          style="width: 200px"
          @pressEnter="loadBankList(1)"
          @blur="loadBankList(1)"
        />
      </a-form-item>
    </a-form>
    <div class="table-part">
      <a-table
        :loading="loading"
        :pagination="false"
        :custom-header-row="() => { return {class: 'my-table-tr'}}"
        :columns="columns"
        :data-source="banks"
        :row-selection="{
          onChange: tableSelect,
          selectedRowKeys: selectedRowKeys,
          type: 'radio',
          columnTitle: $t('common_text_001'),
          columnWidth: 120
        }"
      />
      <table-page
        :total="total"
        :page-opts="searchQuery"
        @update-page="updatePage"
      />
    </div>
  </a-modal>
</template>
<script>
import { reactive, toRefs } from 'vue'
import TablePage from '@/components/table-page'
import { _pay } from '@/api'
import i18n from '@/locale'
export default {
  name: 'BankChoose',
  components: {
    'table-page': TablePage
  },
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props, ctx) {
    const state = reactive({
      loading: false,
      total: 0,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        provinceCode: '',
        cityCode: '',
        parentBankCode: '',
        bankName: ''
      },
      bankObj: {
        bankLineNo: '',
        bankName: ''
      },
      selectedRowKeys: [],
      provinces: [],
      cities: [],
      banks: [],
      generalBanks: [],
      columns: [
        { key: 'index', dataIndex: 'index', title: i18n.global.t('pages_pay_105') },
        { key: 'bankName', dataIndex: 'bankName', title: i18n.global.t('pages_pay_087') },
        { key: 'bankLineNo', dataIndex: 'bankLineNo', title: i18n.global.t('pages_pay_247') }
      ]
    })

    const provinceChange = (code) => {
      if (!code) {
        searchQuery.cityCode = ''
        state.cities = []
      } else {
        loadCities(code)
      }
      loadBankList(1)
    }

    const tableSelect = (selectedRowKeys, record) => {
      state.selectedRowKeys = selectedRowKeys
      state.bankObj.bankLineNo = record[0].bankLineNo
      state.bankObj.bankName = record[0].bankName
    }

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadBankList()
    }

    const handleSubmit = () => {
      ctx.emit('bankChange', state.bankObj)
      ctx.emit('update:visible', false)
    }

    const loadProvinceList = async () => {
      const provinces = []
      const res = await _pay.getCountryArea()
      if (res.data.succ) {
        for (const i in res.data.data) {
          provinces.push({ code: i, name: res.data.data[i] })
        }
        state.provinces = provinces
      }
    }

    const loadCities = async (code) => {
      const cities = []
      const res = await _pay.getCountryArea({ code })
      if (res.data.succ) {
        for (const i in res.data.data) {
          cities.push({ code: i, name: res.data.data[i] })
        }
        state.cities = cities
      }
    }

    const loadGeneralBankList = async () => {
      const res = await _pay.getGeneralBankList()
      if (res.data.succ) {
        state.generalBanks = res.data.data.map(item => {
          return Object.assign({}, item, {
            code: item.bankCode,
            name: item.bankName
          })
        })
      }
    }

    const loadBankList = async (pageNum) => {
      state.loading = true
      if (pageNum) state.searchQuery.pageNum = pageNum
      const query = {
        pageNo: state.searchQuery.pageNum,
        pageSize: state.searchQuery.pageSize,
        request: state.searchQuery
      }
      const res = await _pay.getBankList(query)
      if (res.data.succ) {
        state.banks = res.data.data.results.map((item, index) => {
          return Object.assign({}, item, {
            index,
            key: item.bankName
          })
        })
        state.total = res.data.data.totalRecord
      }
      state.loading = false
    }

    loadProvinceList()
    loadGeneralBankList()

    return {
      updatePage,
      loadBankList,
      tableSelect,
      handleSubmit,
      provinceChange,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
.payee-bank-choose {
  .search-form {
    margin-top: 0;
  }
}
</style>

