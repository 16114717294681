<template>
  <div id="payeeEditInbounds">
    <header-nav :content="`${$t('pages_pay_111')} / ${ editForm.id ? $t('pages_pay_305') : $t('pages_pay_204')}`" />
    <div class="common-card-body">
      <a-steps :current="0" size="small" class="payee-edit-steps">
        <a-step :title="$t('pages_pay_014')" />
        <a-step :title="$t('pages_pay_015')" />
        <a-step :title="$t('pages_pay_016')" />
      </a-steps>
      <a-form
        ref="editRef"
        :model="editForm"
        :rules="editRules"
        class="payee-edit-form ant-round-form"
        layout="vertical"
      >
        <div class="area-title">{{ $t('pages_pay_205') }}</div>
        <div class="area-form">
          <a-form-item name="subjectType" :required="true" :label="$t('pages_pay_089')">
            <a-radio-group v-model:value="editForm.subjectType" button-style="solid" @change="subjectChange">
              <a-radio-button class="radio-left" value="00">{{ $t('pages_pay_207') }}</a-radio-button>
              <a-radio-button class="radio-right" value="01">{{ $t('pages_pay_206') }}</a-radio-button>
            </a-radio-group>
          </a-form-item>

          <a-form-item name="payeeName" :label="editForm.subjectType === '00' ? $t('pages_pay_208') : $t('pages_pay_209')">
            <a-input
              v-model:value="editForm.payeeName"
              allow-clear
              :placeholder="$t('common_text_006') + (editForm.subjectType === '00' ? $t('pages_pay_208') : $t('pages_pay_209'))"
            />
          </a-form-item>

          <a-form-item name="certNo" :label="editForm.subjectType === '00' ? $t('pages_pay_091') : $t('pages_pay_092')">
            <a-input
              v-model:value="editForm.certNo"
              allow-clear
              :placeholder="$t('common_text_006') + (editForm.subjectType === '00' ? $t('pages_pay_091') : $t('pages_pay_092'))"
            />
          </a-form-item>

          <div v-if="!isWhiteList">
            <a-form-item name="businessType" :label="$t('pages_pay_195')">
              <a-select
                v-model:value="editForm.businessType"
                mode="multiple"
                :placeholder="$t('common_text_005') + $t('pages_pay_195')"
                @change="businessChange"
              >
                <a-select-option
                  v-for="business in businessTypeList"
                  :key="business.code"
                  :value="business.code"
                >{{ business.name }}</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item v-if="editForm.businessType.indexOf('00') !== -1" name="payeeType" :label="$t('pages_pay_210')">
              <a-select
                v-model:value="editForm.payeeType"
                allow-clear
                :placeholder="$t('common_text_005') + $t('pages_pay_210')"
                @change="payeeChange"
              >
                <a-select-option
                  v-for="payeeType in payeeTypeList"
                  :key="payeeType.code"
                  :value="payeeType.code"
                >{{ payeeType.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </div>
        </div>

        <div class="area-title">{{ $t('pages_pay_211') }}</div>
        <div class="area-form">
          <a-form-item name="bankAcctNo" :label="$t('pages_pay_088')">
            <a-input
              v-model:value="editForm.bankAcctNo"
              allow-clear
              :placeholder="$t('common_text_006') + $t('pages_pay_088')"
              @blur="handleFindBank"
            />
          </a-form-item>

          <a-form-item name="branchName" :label="$t('pages_pay_143')">
            <div class="flex-align">
              <a-input
                v-model:value="editForm.branchName"
                disabled
                allow-clear
                :placeholder="$t('common_text_006') + $t('pages_pay_143')"
              />
              <a-button
                v-if="editForm.subjectType === '01'"
                type="primary"
                ghost
                shape="round"
                style="margin-left: 36px"
                @click="() => visible = true"
              >{{ $t('pages_pay_212') }}</a-button>
            </div>
          </a-form-item>
        </div>

        <div class="area-title">{{ $t('pages_pay_213') }}</div>
        <div class="area-form">
          <a-form-item name="mobile" :label="$t('pages_pay_093')">
            <a-input
              v-model:value="editForm.mobile"
              allow-clear
              :placeholder="$t('common_text_006') + $t('pages_pay_093')"
            />
          </a-form-item>
        </div>

        <div v-if="!isWhiteList && editForm.payeeType">
          <div class="area-title">{{ $t('pages_pay_213') }}</div>
          <div class="area-form">
            <a-form-item
              v-if="(editForm.subjectType === '01' && ['1001', '1002', '1003', '1004', '1005'].includes(editForm.payeeType))
                || (editForm.subjectType === '00' && ['2002', '2003', '2004'].includes(editForm.payeeType))"
              name="supplementaryMaterialsPath"
              :label="$t('pages_pay_216')"
            >
              <upload-auto
                ref="uploadSRef"
                v-model:file-content="editForm.supplementaryMaterialsPath"
                list-type="picture"
                :accept="'.jpg,.png,.jepg,.zip,.rar,.pdf,.word'"
                :notes="'*' + $t('pages_pay_222') + $t('pages_pay_223')"
                :explain="`${$t('pages_pay_220')}${
                  editForm.payeeType === '1001' ? $t('pages_pay_224') :
                  editForm.payeeType === '1002' ? $t('pages_pay_225') :
                  editForm.payeeType === '1003' ? $t('pages_pay_226') :
                  editForm.payeeType === '1004' ? $t('pages_pay_227') :
                  editForm.payeeType === '1005' ? $t('pages_pay_221') :
                  editForm.payeeType === '2002' ? $t('pages_pay_230') :
                  editForm.payeeType === '2003' ? $t('pages_pay_232') : $t('pages_pay_233')
                }`"
                :max-count="10"
              />
            </a-form-item>

            <a-form-item
              v-if="(editForm.subjectType === '01' && editForm.payeeType === '1006')
                || (editForm.subjectType === '00' && editForm.payeeType === '2004')"
              name="businessLicensePath"
              :label="$t('pages_pay_217')"
            >
              <upload-auto
                ref="uploadBRef"
                v-model:file-content="editForm.businessLicensePath"
                list-type="picture"
                :is-down="editForm.payeeType === '2004'"
                :accept="editForm.payeeType === '2004' ? '.jpg,.png,.jepg,.pdf,.bmp' : '.jpg,.png,.jepg,.zip,.rar,.pdf,.word'"
                :notes="'*' + $t('pages_pay_222') + $t('pages_pay_223')"
                :explain="`${$t('pages_pay_220')}${editForm.payeeType === '1006' ? $t('pages_pay_228') : $t('pages_pay_236')}`"
                :max-count="10"
                @down="downTemplate"
              />
            </a-form-item>

            <a-form-item
              v-if="editForm.subjectType === '00'
                && editForm.businessType.indexOf('00') !== -1
                && editForm.payeeType"
              name="frontCardPath"
              :label="$t('pages_pay_218')"
            >
              <upload-auto
                ref="uploadCardFRef"
                v-model:file-content="editForm.frontCardPath"
                list-type="picture"
                accept=".jpg,.png,.jepg,.pdf,.bmp"
                :notes="'*' + $t('pages_pay_222') + $t('pages_pay_223')"
                :explain="`${$t('pages_pay_220')}${$t('pages_pay_229')}`"
                :max-count="10"
              />
            </a-form-item>

            <a-form-item
              v-if="editForm.subjectType === '00'
                && editForm.businessType.indexOf('00') !== -1
                && editForm.payeeType"
              name="backCardPath"
              :label="$t('pages_pay_219')"
            >
              <upload-auto
                ref="uploadCardBRef"
                v-model:file-content="editForm.backCardPath"
                list-type="picture"
                accept=".jpg,.png,.jepg,.pdf,.bmp"
                :notes="'*' + $t('pages_pay_222') + $t('pages_pay_223')"
                :explain="`${$t('pages_pay_220')}${$t('pages_pay_231')}`"
                :max-count="10"
              />
            </a-form-item>

            <a-form-item
              v-if="editForm.subjectType === '01'
                && editForm.businessType.indexOf('00') !== -1
                && editForm.payeeType && editForm.payeeType !== '1006'"
              name="legalCertNo"
              :label="$t('pages_pay_239')"
            >
              <a-input
                v-model:value="editForm.legalCertNo"
                allow-clear
                :placeholder="$t('common_text_006') + $t('pages_pay_239')"
              />
            </a-form-item>

            <a-form-item
              v-if="editForm.subjectType === '01'
                && editForm.businessType.indexOf('00') !== -1
                && editForm.payeeType
                && editForm.payeeType !== '1006'"
              name="legalFrontCardPath"
              :label="$t('pages_pay_237')"
            >
              <upload-auto
                ref="uploadLFRef"
                v-model:file-content="editForm.legalFrontCardPath"
                list-type="picture"
                accept=".jpg,.png,.jepg,.pdf,.bmp"
                :notes="'*' + $t('pages_pay_222') + $t('pages_pay_223')"
                :explain="`${$t('pages_pay_220')}${$t('pages_pay_240')}`"
                :max-count="10"
              />
            </a-form-item>

            <a-form-item
              v-if="editForm.subjectType === '01'
                && editForm.businessType.indexOf('00') !== -1
                && editForm.payeeType
                && editForm.payeeType !== '1006'"
              name="legalBackCardPath"
              :label="$t('pages_pay_238')"
            >
              <upload-auto
                ref="uploadLBRef"
                v-model:file-content="editForm.legalBackCardPath"
                list-type="picture"
                accept=".jpg,.png,.jepg,.pdf,.bmp"
                :notes="'*' + $t('pages_pay_222') + $t('pages_pay_223')"
                :explain="`${$t('pages_pay_220')}${$t('pages_pay_241')}`"
                :max-count="10"
              />
            </a-form-item>

          </div>
        </div>

        <div class="area-form" style="margin-top: 48px">
          <a-button
            type="primary"
            shape="round"
            style="min-width: 138px"
            :loading="loading"
            @click="handleSubmit"
          >{{ $t('common_text_002') }}</a-button>
          <a-button
            type="primary"
            shape="round"
            style="min-width: 138px; margin-left: 36px"
            ghost
            @click="handleReset"
          >{{ $t('common_text_040') }}</a-button>
        </div>
      </a-form>
    </div>
    <bank-choose
      v-model:visible="visible"
      @bankChange="bankChange"
    />
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import HeaderNav from '@/components/header-nav'
import UploadAuto from '@/components/upload-auto'
import BankChoose from './components/bank-choose'
import { getAttrStatusList } from '@/util'
import { _pay } from '@/api'
import i18n from '@/locale'
export default {
  name: 'PayeeEditInbounds',
  components: {
    'header-nav': HeaderNav,
    'upload-auto': UploadAuto,
    'bank-choose': BankChoose
  },
  setup () {
    const validatorBankNo = (rule, value) => {
      if (!value) return Promise.reject(i18n.global.t('common_text_006') + i18n.global.t('pages_pay_088'))
      if (value.length > 32) return Promise.reject(i18n.global.t('pages_pay_214'))
      if (state.resMsg) return Promise.reject(state.resMsg)
      return Promise.resolve()
    }
    const editRef = ref(null)
    const uploadSRef = ref(null)
    const uploadBRef = ref(null)
    const uploadCardFRef = ref(null)
    const uploadCardBRef = ref(null)
    const uploadLFRef = ref(null)
    const uploadLBRef = ref(null)

    const route = useRoute()
    const router = useRouter()

    const state = reactive({
      visible: false,
      loading: false,
      isWhiteList: false,
      resMsg: '',
      editForm: {
        id: '',
        subjectType: '00',
        payeeName: '',
        certNo: '',
        bankAcctNo: '',
        branchName: '',
        bankCode: '',
        country: 'CN',
        currency: 'CNY',
        mobile: '',
        businessType: [],
        payeeType: '',
        supplementaryMaterialsPath: '',
        businessLicensePath: '',
        frontCardPath: '',
        backCardPath: '',
        legalFrontCardPath: '',
        legalBackCardPath: '',
        legalCertNo: ''
      },
      editRules: {
        payeeName: [{ required: true, max: 64 }],
        certNo: [{ required: true, len: 18 }],
        bankAcctNo: [{ required: true, max: 32, validator: validatorBankNo }],
        businessType: [{ required: true }],
        payeeType: [{ required: true }],
        branchName: [{ required: true, max: 256 }],
        mobile: [{ required: true, len: 11 }],
        supplementaryMaterialsPath: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_pay_216') }],
        businessLicensePath: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_pay_217') }],
        // frontCardPath: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_pay_218') }],
        // backCardPath: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_pay_219') }],
        // legalFrontCardPath: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_pay_238') }],
        // legalBackCardPath: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_pay_239') }],
        legalCertNo: [{ required: true, len: 18 }]
      }
    })

    state.businessTypeList = computed(() => {
      return getAttrStatusList('payeeBusinessType')
    })

    state.payeeTypeList = computed(() => {
      return getAttrStatusList(state.editForm.subjectType === '00' ? 'personalPayeeType' : 'enterprisePayeeType')
    })

    const downTemplate = () => {
      window.open(require('../../../../../assets/files/yingYeZhiZhao.png'))
    }

    const subjectChange = (e) => {
      state.resMsg = ''
      editRef.value.resetFields()
      state.editForm.subjectType = e.target.value
      state.editForm.bankCode = ''
    }

    const businessChange = () => {
      state.editForm.payeeType = ''
      payeeChange()
    }

    const bankChange = ({ bankLineNo, bankName }) => {
      state.editForm.bankCode = bankLineNo
      state.editForm.branchName = bankName
    }

    const payeeChange = () => {
      state.editForm.supplementaryMaterialsPath = ''
      state.editForm.businessLicensePath = ''
      state.editForm.frontCardPath = ''
      state.editForm.backCardPath = ''
      state.editForm.legalFrontCardPath = ''
      state.editForm.legalBackCardPath = ''
      state.editForm.legalCertNo = ''

      if (uploadSRef.value) {
        uploadSRef.value.fileData = []
        uploadSRef.value.fileList = []
      }
      if (uploadBRef.value) {
        uploadBRef.value.fileData = []
        uploadBRef.value.fileList = []
      }
      if (uploadCardFRef.value) {
        uploadCardFRef.value.fileData = []
        uploadCardFRef.value.fileList = []
      }
      if (uploadCardBRef.value) {
        uploadCardBRef.value.fileData = []
        uploadCardBRef.value.fileList = []
      }
      if (uploadLFRef.value) {
        uploadLFRef.value.fileData = []
        uploadLFRef.value.fileList = []
      }
      if (uploadLBRef.value) {
        uploadLBRef.value.fileData = []
        uploadLBRef.value.fileList = []
      }
    }

    const handleFindBank = async () => {
      state.resMsg = ''
      state.editForm.bankAcctNo = state.editForm.bankAcctNo.trim()
      const { subjectType, bankAcctNo } = state.editForm
      if (subjectType === '01' || !bankAcctNo) return
      const res = await _pay.findBankName({ bin: bankAcctNo })
      if (res.data.succ) {
        const { data } = res.data
        state.editForm.branchName = data.bankName
        state.editForm.bankCode = data.bankLineCode
      } else {
        state.editForm.branchName = ''
        state.editForm.bankCode = ''
        state.resMsg = res.data.resMsg
      }
      editRef.value.validateFields('bankAcctNo')
    }

    const handleSubmit = () => {
      editRef.value.validateFields()
        .then(values => {
          state.loading = true
          const data = Object.assign({}, state.editForm, {
            businessType: state.editForm.businessType.join(',')
          })
          return _pay.updatePayee(data)
        })
        .then(res => {
          state.loading = false
          if (res && res.data.succ) {
            message.success(i18n.global.t(state.editForm.id ? 'common_text_044' : 'common_text_043'))
            router.replace({ path: '/funds/payee_manage', query: { key: 'inbound' }})
          } else {
            message.error(res.data.resMsg)
          }
        }).catch(err => console.log(err))
    }

    const handleReset = () => {
      state.resMsg = ''
      editRef.value.resetFields()
      state.editForm.subjectType = '00'
      state.editForm.bankCode = ''
    }

    const loadIsWhiteList = async (payeeId) => {
      const query = {}
      if (payeeId) query.payeeId = payeeId
      const res = await _pay.getIsWhiteList(query)
      if (res.data.succ) state.isWhiteList = res.data.data
    }

    const loadPayeeDetail = async (payeeId) => {
      const res = await _pay.getPayeeDetail({ id: payeeId })
      if (res.data.succ) {
        const { data } = res.data
        state.editForm = Object.assign({}, data, {
          businessType: data.businessType ? data.businessType.split(',') : []
        })
        nextTick(() => {
          const { supplementaryMaterialsPath, businessLicensePath, frontCardPath, backCardPath, legalFrontCardPath, legalBackCardPath } = state.editForm
          if (supplementaryMaterialsPath) uploadSRef.value.fileListRefresh(supplementaryMaterialsPath.split(','))
          if (businessLicensePath) uploadBRef.value.fileListRefresh(businessLicensePath.split(','))
          if (frontCardPath) uploadCardFRef.value.fileListRefresh(frontCardPath.split(','))
          if (backCardPath) uploadCardBRef.value.fileListRefresh(backCardPath.split(','))
          if (legalFrontCardPath) uploadLFRef.value.fileListRefresh(legalFrontCardPath.split(','))
          if (legalBackCardPath) uploadLBRef.value.fileListRefresh(legalBackCardPath.split(','))
        })
      }
    }

    loadIsWhiteList(route.query.payeeId)
    if (route.query.payeeId) loadPayeeDetail(route.query.payeeId)

    return {
      editRef,
      uploadSRef,
      uploadBRef,
      uploadCardFRef,
      uploadCardBRef,
      uploadLFRef,
      uploadLBRef,
      bankChange,
      downTemplate,
      subjectChange,
      businessChange,
      payeeChange,
      handleSubmit,
      handleReset,
      handleFindBank,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#payeeEditInbounds {
  .common-card-body {
    padding: 36px 24px;
    .payee-edit-form {
      margin-top: 36px;
      text-align: left;
      padding: 0 36px;
      .area-title {
        font-size: 15px;
        font-weight: 500;
        padding-bottom: 6px;
        border-bottom: 1px solid #f0f0f0;
        letter-spacing: 1px;
        margin-top: 24px;
      }
      .area-form {
        width: 500px;
        padding: 0 24px;
        margin-top: 24px;
        .radio-left, .radio-right {
          padding: 0 58px;
          height: 35px;
          line-height: 35px;
          border-radius: 25px 0 0 25px;
        }
        .radio-right {
          border-radius: 0 25px 25px 0;
        }
      }
    }
  }
}
</style>
